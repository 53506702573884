import { Component } from '@angular/core';
import { ApiService } from '../Services/apiservice.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {
  title = 'icite';
  loadingMsg: any;
  search = "";
  ref:any;
  showData = false;
  showCount = false;
  executionTime = false;
  loading = false;
  data:any = [];
  resultsNotfound = '';
  apiData :any = [];
  articleapi:any;
  journalapi:any ;
  affiliations:any;
  references:any;  
  selectedTab = 'all';
 

  constructor(private apiService: ApiService) {}

  ngOnInit(): void {}
     enter(ref: any) {
    this.loading = true;
    (<HTMLInputElement>document.getElementById('searchArea')).style.marginTop ='55px';
    this.loadingMsg = 'Loading Data...';
    this.showData = false;
    this.showCount = false;
    this.resultsNotfound = '';
    
    this.apiService.enterDoi(ref).subscribe((data) => {
      this.loading = false;
      if(data.status == "failed" ){
        this.resultsNotfound = data.message ;
        console.log(data);
        return;
      }
      // debugger
      this.articleapi = [];
      this.journalapi = [];
      this.affiliations = [];
      this.references = [];
      
      console.log('data',data.results);
      this.showCount = data.result_count;
      this.executionTime = data.execution_time;

         data.results.forEach((item:any) => {

          if(item.hasOwnProperty('articleId')){
          this.articleapi.push(item);
          this.showData = true;
          } 
          else if(item.hasOwnProperty('JournalId')) {
          this.journalapi.push(item);
          this.showData = true;
         }
          else if(item.hasOwnProperty('givenName'))  {
          this.affiliations.push(item);
          this.showData = true;
          }
          else if(item.hasOwnProperty('journalTitle'))  {
            this.references.push(item);
            this.showData = true;
          }
      });
    },
    )}
    
  get (count : number ){
    return this.articleapi.length;
    return this.journalapi.length;
    return this.affiliations.length;
    return this.references.length;
  }
 
  onKeyUp(ref: any) {
    this.enter(ref);
  
  }
}
