
<nav class="navbar  box-shadow d-flex ">
     <div class="row col-12 ">

        <div class="col-1 d-flex justify-content-center">
            <i><b class="logo1">Blazon</b></i>
        </div>
        
        <div class="col-3 mt-2 logo2">
            <i><b>Intelligent Publishing Platform</b></i>
        </div>
         
          <div class="col-6 mt-2 ">
             <span> 
                 <a class="nav-items"  href="#" [routerLink]="['/home']">Home&nbsp;<i class="fa fa-home " aria-hidden="true"></i></a></span>
             <span>
                <a class="nav-items" href="#" [routerLink]="['/journal']">Create Journal&nbsp;<i class="fa fa-file-text" aria-hidden="true"></i></a>
             </span>
            <span>
                <a class="nav-items" href="#" [routerLink]="['/upload-content']">Upload-Content &nbsp;<i class="fas fa-cloud-download"></i></a>      
            </span>
            <span>
                <a class="nav-items" href="#" [routerLink]="['/published']">Published &nbsp;<i class="far fa-tasks"></i></a>
             </span>
          </div>
 
          <div class="col-1 time mt-1 d-flex justify-content-end">
             {{ time | date : "hh:mm:ss a" }}
         </div>
  
         <div class="col d-flex justify-content-start">
            <i class="fas fa-sign-out fa-2x " aria-hidden="true"></i>
          </div>
 
         </div>
    <!-- </div> -->
      </nav>
  
  