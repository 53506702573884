import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  time = new Date();
  intervalId: any;
  googleForm!: FormGroup;
  url: any = '';
  value: string = '';
  search1 = '';

  constructor(private search: FormBuilder) {}

  ngOnInit(): void {
    this.intervalId = setInterval(() => {
      this.time = new Date();
    }, 1);
    this.googleForm = this.search.group({
      google: [''],
    });
  }
  submit() {
    if (
      (<HTMLInputElement>document.getElementById('form1')).value.replace(
        /\s/g,
        ''
      ) == ''
    ) {
      this.search1 = '';
    } else {
      console.log(this.googleForm.value.google);
      this.url = this.googleForm.value.google;
    }
  }
}
