import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from '../core/core.module';
import { HomePageComponent } from '../core/home-page/home-page.component';
import { PagesComponent } from './pages.component';
import { PagesModule } from './pages.module';

const pagesRoutes: Routes = [
  { path: 'journal', component: PagesComponent ,loadChildren: () => import('./journal/journal.module').then(m => m.JournalModule) },
  // {path:'dashboard',component:PagesComponent},
  {path:'home',component:PagesComponent,loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  {path:'upload-content',component:PagesComponent,loadChildren: () => import('./upload-content/upload-content.module').then(m => m.UploadContentModule) },
  {path:'published',component:PagesComponent,loadChildren:() => import('./published/published.module').then(m => m.PublishedModule )}
];

@NgModule({
  imports: [RouterModule.forChild(pagesRoutes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
