import { Injectable,OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
// import { environment } from 'src/environment/environment';


@Injectable({
  providedIn: 'root'
})
export class ApiService implements OnInit {
  public files :any= ''

  constructor(private http: HttpClient) {}
  ngOnInit(): void {}

 /////////HomePage/ search for article profile////////
 public enterDoi(ref: any): Observable<any> {
  let url = `${environment.baseUrl}/iCite?ref=${ref}`;
  return this.http.post(url,ref);
}

////////Searching journal profile in search bar////
// public journal_profile(ref:any): Observable <any> {
//    let url =`http://10.10.31.172:52386/journal_profile?ref=${ref}`;
//    return this.http.post(url,ref)
// }

// //////////Searching Author affiliations in search bar////
// public author_affiliation(ref:any): Observable <any> {
//   // debugger
//   // let newstring = ref.split(" ");
//   // let obj = { input1 :newstring[0], input2:newstring[1]};
//     // let obj = { input1 :ref,input2: "" };
//   let url =`http://10.10.31.172:52386/author_affiliation?ref=${ref}`;
//   return this.http.post(url,ref);
// }
}