import { Component } from '@angular/core';

@Component({
  selector: 'app-pages',
  template: `<app-master> </app-master>`,
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent {

}
