
  
  <nav class="navbar p-0 navbar-expand border-bottom box-shadow navbar-custom ">
    <!-- <div class="col justify-content-start">
      <img src="../../assets/logo.png" id="img">
    </div> -->
    <div class="col-10">

    </div>
    <div class="col-2 d-flex justify-content-center me-3">
      <button [routerLink]="['/home']" type="button" class="btn btn-primaryadd">
        Login
      </button>
    </div>
  </nav>
  
  <div class="card ">
    <div class="row input-group d-flex justify-content-center" id="searchArea">
      <p class="heading">Blazon</p>
      <p style="font-size: 1.6vw;" class="mt-2">Explore, discover, and create content with ease</p>
      <div class="row" id="searchArea1">
        <div class="input-group text-center d-flex justify-content-center align-items-center">
          <input type="search"  class="form-controld" #ref id="form1" autocomplete="off" (keyup.enter)="onKeyUp(ref.value)"
            placeholder=" Search by keywords,Articles,Journals,Author & Affiliations,References">
          <button class="btn btn-primary" (click)="enter(ref.value)">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  
  
  <div *ngIf="showCount" class="loadingMsg1  p-3">About {{showCount}} results ({{executionTime}})</div> 
  <div *ngIf="loading" class="loadingMsg d-flex justify-content-center mt-1 ">
    {{loadingMsg}} <i class="fa fa-spinner fa-spin" style="font-size:29px;color: #3155a6;"></i>
  </div>
  <div class="row searchedResults" *ngIf= "showData">
  
    <div class="container " >
      <!-- <ul class="nav nav-tabs" >

      <li class="nav-item"  >
        <a class="nav-link" [ngClass]="{ 'active':true}"  data-toggle = "tab" aria-current="page" (click)="selectedTab = 'all'"  href="#all">All</a>
      </li>

      <li class="nav-item">
        <a class="nav-link" (click)="selectedTab = 'journals'"   data-toggle = "tab" href="#all">Journals</a>
      </li>

      <li class="nav-item">
        <a class="nav-link" (click)="selectedTab = 'articles'"   data-toggle = "tab" href="#all">Articles</a>
      </li>

      <li class="nav-item">
        <a class="nav-link"  (click)="selectedTab = 'givenName'"  data-toggle = "tab" href="#all">Author & Affiliations</a>
      </li>
      
      <li class="nav-item">
        <a class="nav-link"  (click)="selectedTab = 'journaltitle'"  data-toggle = "tab" href="#all">References</a>
      </li>
    </ul> -->
   </div>
  
   <div class="tab-content-container">
    <div class="tab-content-wrapper">
      <div id="all">

        <div *ngFor="let item of articleapi;let i = index">
         <div>
          <tr>
            <td style="width: 10vw; font-weight: bold;">Article Title </td>
            <td>
              <a [href]="'https://onlinelibrary.wiley.com/doi/full/' + item.articleDoi" target="_blank">{{ item.articleTitle }}</a>
            </td>
          </tr> 
           </div>
     
         <div>
          <tr>
            <td style="width: 10vw; font-weight: bold;">Abstract </td>
            <!-- <span data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample"><a href="#">Read abstract...</a></span> -->
              <!-- <div id="collapseExample" class="collapse"> -->
                  <td>{{item.abstract}}</td>
              <!-- </div> -->
          </tr>
          <br>
         </div>
        </div>

        <!-- <table class="table table-striped" id="TablesId" *ngIf="affiliations.length  && (selectedTab === 'givenName'|| selectedTab === 'all')">
          <tbody>
            <div *ngFor="let item of affiliations;let i = index">
              <tr>
                <td style="width: 15vw; font-weight: bold;">Affiliation Ref</td>
                <td style="width: 80%;">{{item.AffiliationRef}} </td>
               </tr> 
               <tr>
                <td style="width: 15vw; font-weight: bold;">affiliation Ref</td>
                <td>{{item.affiliationRef}} </td>
               </tr> 
               <tr>
                <td style="width: 15vw; font-weight: bold;">biographyInfo</td>
                <td>{{item.biographyInfo}} </td>
               </tr> 
               <tr>
                <td style="width: 15vw; font-weight: bold;">City</td>
                <td>{{item.city}} </td>
               </tr> 
               <tr>
                <td style="width: 15vw; font-weight: bold;">Country</td>
                <td>{{item.country}} </td>
               </tr> 
               <tr>
                <td style="width: 15vw; font-weight: bold;">CountryPart</td>
                <td>{{item.countryPart}} </td>
               </tr> 
               <tr>
                <td style="width: 15vw;font-weight: bold;">Degrees</td>
                <td>{{item.degrees}}</td>
               </tr> 
            
               <tr>
                <td style="width: 15vw;font-weight: bold;">Email</td>
                <td>{{item.email}} </td>
               </tr> 
               <tr>
                <td style="width: 15vw; font-weight: bold;">Family Name</td>
                <td>{{item.familyName}} </td>
               </tr>
               <tr>
                <td style="width: 15vw; font-weight: bold;">Family Name Prefix</td>
                <td>{{item.familyNamePrefix}} </td>
               </tr>
               <tr>
                <td style="width: 15vw; font-weight: bold;">Fax</td>
                <td>{{item.fax}} </td>
               </tr> 
               <tr>
                <td style="width: 15vw; font-weight: bold;">Given Name</td>
                <td>{{item.givenName}} </td>
               </tr> 
               <tr>
                <td style="width: 15vw; font-weight: bold;">Honorifics</td>
                <td>{{item.honorifics}} </td>
               </tr>
               <tr>
                <td style="width: 15vw; font-weight: bold;">Job Title</td>
                <td>{{item.jobTitle}} </td>
               </tr>
               <tr>
                <td style="width: 15vw; font-weight: bold;">Name Suffix</td>
                <td>{{item.nameSuffix}} </td>
               </tr>
               <tr>
                <td style="width: 15vw; font-weight: bold;">ORCID</td>
                <td>{{item.orcid}} </td>
               </tr>
               <tr>
                <td style="width: 15vw; font-weight: bold;">Organisation Div</td>
                <td>{{item.organisationDiv}} </td>
               </tr>
               <tr>
                <td  style="width: 15vw; font-weight: bold;">Organisation Name</td>
                <td>{{item.organisationName}} </td>
               </tr>
               <tr>
                <td  style="width: 15vw; font-weight: bold;">Phone</td>
                <td>{{item.phone}} </td>
               </tr>
               <tr>
                <td  style="width: 15vw; font-weight: bold;">Post Code</td>
                <td>{{item.postCode}} </td>
               </tr>
               <tr>
                <td  style="width: 15vw;font-weight: bold;">Preferred Pronouns</td>
                <td>{{item.preferredPronouns}} </td>
               </tr>
               <tr>
                <td  style="width: 15vw; font-weight: bold;">Street</td>
                <td>{{item.street}} </td>
               </tr>
               <tr>
                <td  style="width: 15vw; font-weight: bold;">Titles After Names</td>
                <td>{{item.titlesAfterNames}} </td>
               </tr>
               <tr>
                <td  style="width: 15vw; font-weight: bold;">URL</td>
                <td>{{item.url}} </td>
               </tr><br>
      </div>
          </tbody>
        </table> -->
    
        <!-- <table class="table table-striped " id="TablesId"  *ngIf="articleapi.length  && (selectedTab === 'articles' || selectedTab === 'all')">
              <tbody>
                <div *ngFor="let item of articleapi;let i = index">
                  <tr>
                   <td style="width: 10vw; font-weight: bold;">Article Language</td>
                   <td>{{item.articleLang}} </td>
                  </tr>
                  <tr>
                   <td style="width: 10vw; font-weight: bold;">Type</td>
                   <td>{{item.type}}</td>
                 </tr>
                 <tr>
                  <td style="width: 10vw; font-weight: bold;">Article Title </td>
                  <td>
                    <a [href]="'https://onlinelibrary.wiley.com/doi/full/' + item.articleDoi" target="_blank">{{ item.articleTitle }}</a>
                  </td>
                </tr> 
                 <tr>
                   <td style="width: 10vw; font-weight: bold;">Article DOI</td>
                   <td>{{item.articleDoi}}</td>
                 </tr>
                 <tr>
                   <td style="width: 10vw; font-weight: bold;">Article Id</td>
                   <td>{{item.articleId}}</td>
                 </tr>
                 <tr>
                   <td style="width: 10vw; font-weight: bold;">Article Category </td>
                   <td>{{item.articleCategory}}</td>
                 </tr>
                 <tr>
                   <td style="width: 10vw; font-weight: bold;">SubTitle </td>
                   <td>{{item.subTitle}}</td>
                 </tr>
                 <tr>
                   <td style="width: 10vw; font-weight: bold;">Abstract </td>
                   <span data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample"><a href="#">Read abstract...</a></span>
                     <div id="collapseExample" class="collapse">
                         <td>{{item.abstract}}</td>
                     </div>
                 </tr>
                 <tr>
                   <td style="width: 10vw; font-weight: bold;">Keywords</td>
                   <td> {{item.keywords}}</td>
                 </tr>
                 <tr>
                   <td style="width: 10vw; font-weight: bold;">Acknowledgments </td>
                  <td>
                    <span *ngFor="let acknowledgment of item.acknowledgments">
                      Title: {{acknowledgment.title}}  Para:{{acknowledgment.para}}
                    </span>
                  </td>
                 </tr>
                 <tr>
                   <td style="width: 10vw; font-weight: bold;">Data Availability </td>
                  <td>
                    <span  *ngFor="let DataAvail of item.dataAvailability">
                      Title: {{DataAvail.title}} Para: {{DataAvail.para}} 
                    </span>
                  </td>
                 </tr>
                 <tr>
                   <td style="width: 10vw; font-weight: bold;">Conflict </td>
                  <td>
                    <span *ngFor="let conflictData of item.conflict">
                      Title: {{conflictData.title}} Para: {{conflictData.para}} 
                     </span>
                  </td>
                 </tr>
                 <tr>
                  <td style="width: 10vw; font-weight: bold;">Ethics Statements </td>
                  <td style="width: 100%;">
                    <span data-bs-toggle="collapse" href="#collapseExample1" role="button" aria-expanded="false" aria-controls="collapseExample1"><a href="#">Read ethicsStatements...</a></span>
                    <span *ngFor="let ethics of item.ethicsStatements">
                      <div id="collapseExample1" class="collapse">
                        Title: {{ethics.title}}
                        Para:{{ethics.para}}                   
                      </div>
                    </span>
                    </td>
                 </tr>
                <br>
                </div>
              </tbody>
        </table> -->
        
        <!-- <table class="table table-striped" id="TablesId" *ngIf="journalapi.length  && (selectedTab === 'journals' || selectedTab === 'all')">
          <tbody>
            <div *ngFor="let item of journalapi;let i = index">
              <tr>
                <td style="width: 15vw; font-weight: bold;">Journal Language</td>
                <td style="width: 80%;">{{item.JournalLang}} </td>
               </tr>
               <tr>
                <td style="width: 15vw; font-weight: bold;">Journal Info</td>
                <td>{{item.JournalInfo}} </td>
               </tr>
               <tr>
                <td style="width: 15vw; font-weight: bold;">DOI</td>
                <td>{{item.doi}} </td>
               </tr>
               <tr *ngFor="let essnData of item.issn">
                <td style="width: 20vw; font-weight: bold;">iSSN </td>
                <td>electronic : {{essnData.electronic}} , print:{{essnData.print}}</td>
              </tr>
               <tr>
                <td style="width: 15vw; font-weight: bold;">Journal Id</td>
                <td>{{item.JournalId}} </td>
               </tr>
               <tr>
                <td style="width: 15vw; font-weight: bold;">Journal Name</td>
                <td><a href="#">{{item.JournalName}}</a> </td>
               </tr>
               <tr>
                <td style="width: 15vw; font-weight: bold;">Journal Abbreviated</td>
                <td>{{item.JournalAbbreviated}} </td>
               </tr>
               <tr>
                 <td style="width: 15vw; font-weight: bold;">Publisher Name</td>
                 <td>{{item.PublisherName}}</td>
               </tr>
              <br>
               </div>
          </tbody>
        </table> -->
       
          <!-- <table class="table table-striped" id="TablesId" *ngIf="references.length && (selectedTab === 'journaltitle'|| selectedTab === 'all')">
            <tbody>
                <div *ngFor="let item of references;let i= index">
                    <tr>
                       <td style="width: 15vw; font-weight: bold;">Type</td>
                       <td style="width: 80%;"> {{item.type}} </td>
                    </tr>
                    <tr>
                      <td style="width: 15vw; font-weight: bold;">Authors</td>
                      <span *ngFor="let authorsData of item.authors"> 
                        {{authorsData.lastname}} {{authorsData.firstname}} {{authorsData.middlename}} &nbsp; &nbsp; 
                      </span>
                     </tr>
                   <tr>
                    <td style="width: 15vw; font-weight: bold;" >Groups</td>
                    <td>{{item.groups}}</td>
                      
                   </tr>
                   <tr>
                     <td style="width: 15vw; font-weight: bold;">First Page</td>
                    <td>{{item.firstPage}} </td>
                   </tr>
                   <tr>
                     <td style="width: 15vw; font-weight: bold;">Last Page</td>
                    <td>{{item.lastPage}} </td>
                   </tr>
                   <tr>
                     <td style="width: 15vw; font-weight: bold;">Issue</td>
                    <td>{{item.issue}} </td>
                   </tr>
                   <tr>
                     <td style="width: 15vw; font-weight: bold;">Article Title</td>
                    <td>{{item.articleTitle}} </td>
                   </tr>
                   <tr>
                     <td style="width: 15vw; font-weight: bold;">Journal Title</td>
                    <td>{{item.journalTitle}} </td>
                   </tr>
                   <tr>
                     <td style="width: 15vw; font-weight: bold;">URL</td>
                    <td>{{item.url}} </td>
                   </tr>
                   <tr>
                     <td style="width: 15vw; font-weight: bold;">Volume</td>
                    <td>{{item.volume}} </td>
                   </tr>
                   <tr>
                     <td style="width: 15vw; font-weight: bold;">Year</td>
                    <td>{{item.year}} </td>
                   </tr>
                   <tr>
                     <td style="width: 15vw; font-weight: bold;">E-Locator</td>
                    <td>{{item.elocator}}</td>
                   </tr>
                   <tr>
                     <td style="width: 15vw; font-weight: bold;">IRID</td>
                    <td>{{item.IRID}}</td>
                   </tr><br>
                    </div>
            </tbody>
          </table>  -->
              
        <!-- </div>
  
     </div>
   </div>

    </div> -->
  
    </div>
    </div>
    </div>
    <div class=" text-center mt-4 " *ngIf="resultsNotfound.length">
      <h5> {{resultsNotfound}}! </h5>
    </div> 