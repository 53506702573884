<div class="container-fluid master">
    <div class="row header">
        <app-header></app-header>
    </div>
    <div class="container-fluid h-100 pr-0">
        <div class="row">
            <nav class="col-md-1-5 pl-auto p-0 sidebar">
                <!-- <app-menubar></app-menubar> -->
            </nav>
            <div class=" mt-5">
                <div class="row">
                    <main role="main" class="main">
                        <router-outlet></router-outlet>
                    </main>
                </div>
            </div>
        </div>
    </div>
</div>